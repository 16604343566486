import React from 'react'
import Image from '~/components/atoms/Image'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function FwImage({key, index, ...props}) {

  const section = props.image
  
  return (
    <section className="my-20 md:my-36" id={`fwimage` + index}>
      <div className="container container--wide px-0">
        <FadeInWhenVisible>
          <Image data={props.image} className={`w-full h-auto`} />        
        </FadeInWhenVisible>
      </div>
    </section>
  )
}
